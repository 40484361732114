import React, { Component } from 'react';
import './style.css';
import Axios from 'axios';

class Testimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testimonials: []
        }
    }
    componentDidMount() {
        Axios.get('/assets/json/testimonial.json')
            .then(resp => {
                if (resp.data) {
                    this.setState({
                        testimonials: resp.data
                    })
                }
            })
            .catch(err => {
                console.error(err)
            })
    }
    render() {
        const { testimonials } = this.state;
        return (
            <div className="testimony">
                <div>
                    <img src="https://i.imgur.com/gZ5HY0U.png" className="img-responsive quote-image" alt="quote" />
                </div>
                {/* Carousel Start below */}
                <div id="carousel1" className="carousel slide testimonial-style" style={{ display: 'block' }} data-ride="carousel">
                    {/* Indicators */}
                    <ol className="carousel-indicators">
                        {
                            !!testimonials && testimonials.map((msg, index) => (
                                <li data-target={`#carousel${index}`} data-slide-to={(index + 1)} className={`${index === 0 ? 'active' : ''}`} />
                            ))
                        }
                    </ol>
                    {/* Wrapper for slides */}
                    <div className="carousel-inner">
                        {
                            !!testimonials && testimonials.map((msg, index) => (
                                <div key={index} className={`item ${index === 0 ? 'active' : ''}`}>
                                    <div className="carousel-caption testimonial-caption-style">
                                        <p>{msg.context}</p>
                                        <p className="testimonial-authors">{msg.author}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    {/* Controls */}
                    <a className="left carousel-control" href="#carousel1" data-slide="prev"> <i className="glyphicon glyphicon-chevron-left" /> </a>
                    <a className="right carousel-control" href="#carousel1" data-slide="next"> <i className="glyphicon glyphicon-chevron-right" /> </a>
                </div>
            </div>
        )
    }
}

export default Testimonial;