import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import routes from '../../routes';


class MobileNav extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  closeNav() {
    document.getElementById("navigation-mobile").style.width = "0";
    document.getElementById("site").style.marginLeft = "0";
  }
  render() {
    return (
      <div id="navigation-mobile" className="navigation-mobile">
        <span className="close-button" onClick={e => this.closeNav()}>×</span>
        <div className="menu-main-menu-container">
          <ul id="mobile-menu" className="menu">
            {
              routes.filter(r => r.isShown).map((link, i) => (
                <li key={i} className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-4${i}`}><Link to={link.path}>{link.name}</Link></li>
              ))
            }
            {/* <li id="menu-item-41" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-41"><Link to="about">About</Link></li>
            <li id="menu-item-42" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-42"><Link to="services">Services</Link></li> */}
            {/* <li id="menu-item-45" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-has-children menu-item-45">
              <a href="index.html" aria-current="page">Page Examples</a>
              <ul className="sub-menu">
                <li id="menu-item-44" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-44"><a href="sample-page/index.html">Page Builder</a></li>
                <li id="menu-item-39" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-39"><a href="standard-page/index.html">Basic Page</a></li>
                <li id="menu-item-155" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-155"><a href="colour-page-builder/index.html">Colour Page Builder</a></li>
              </ul>
            </li>
            <li id="menu-item-204" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-has-children menu-item-204">
              <a href="index.html" aria-current="page">Features</a>
              <ul className="sub-menu">
                <li id="menu-item-50" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-50"><a href="shop/index.html">Shop</a></li>
                <li id="menu-item-207" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-207"><a href="product/test-product-one/index.html">Product</a></li>
                <li id="menu-item-206" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-206"><a href="portfolio/index.html">Portfolio</a></li>
                <li id="menu-item-205" className="menu-item menu-item-type-post_type menu-item-object-portfolio menu-item-205"><a href="portfolio/example-portfolio-3/index.html">Portfolio Item</a></li>
              </ul>
            </li> */}
            {/* <li id="menu-item-43" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-43">
              <Link to="/blog">Blog</Link>
              <ul className="sub-menu">
                <li id="menu-item-208" className="menu-item menu-item-type-post_type menu-item-object-post menu-item-208"><a href="2019/08/17/another-sample-post/index.html">Blog Post</a></li>
                <li id="menu-item-209" className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-209"><a href="category/demo/index.html">Archive</a></li>
              </ul>
            </li>
            <li id="menu-item-40" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-40"><Link to="/contact">Contact</Link></li> */}
          </ul>
        </div>
        {/* <div className="search-mobile">
          <form role="search" method="get" className="search-form" action="https://thrive.snugdesigns.co.uk/">
            <label htmlFor="search-form-5efdae325a5da">
              <span className="screen-reader-text">Search for:</span>
            </label>
            <input type="search" id="search-form-5efdae325a5da" className="search-field" placeholder="Search…" defaultValue name="s" />
            <button type="submit" className="search-submit" value="Search"><i className="fas fa-search" /></button>
          </form>
        </div> */}
      </div>
    )
  }
}

export default MobileNav;