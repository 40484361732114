import React, { Component } from 'react'
import './style.css';
import AdminHeader from '../../../Components/admin-header'
import { connect } from 'react-redux';

class AdminHome extends Component {

    constructor(props) {
        super(props)
        if (!localStorage.getItem('isLoggedIn')) {
            this.props.history.push('/login');
        }
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <div>
                    <AdminHeader />
                    <div className="page-container" style={styleSheet}>
                        <h2>Dashboard</h2>
                        <div class="dashboard">
                            <div class="row">
                                <div class="col-md-3 col-xl-3">
                                    <div class="card bg-c-blue order-card">
                                        <div class="card-block">
                                            <h2 class="text-right"><i class="fa fas fa-blog f-left"></i><span>486</span></h2>
                                            <p class="m-b-0">Total Blogs</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3 col-xl-3">
                                    <div class="card bg-c-green order-card">
                                        <div class="card-block">
                                            <h2 class="text-right"><i class="fa fa-id-card f-left"></i><span>486</span></h2>
                                            <p class="m-b-0">Total Testomials</p>
                                        </div>
                                    </div>
                                </div>

                                {/* <div class="col-md-3 col-xl-3">
                                    <div class="card bg-c-yellow order-card">
                                        <div class="card-block">
                                            <h2 class="text-right"><i class="fa fa-refresh f-left"></i><span>486</span></h2>
                                            <p class="m-b-0">Completed Orders</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3 col-xl-3">
                                    <div class="card bg-c-pink order-card">
                                        <div class="card-block">
                                            <h2 class="text-right"><i class="fa fa-credit-card f-left"></i><span>486</span></h2>
                                            <p class="m-b-0">Completed Orders</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const styleSheet = {
    margin: '3vw 10vw'
}

const mapStateToProps = (state) => {
    const uid = state.firebase.auth.uid ? state.firebase.auth.uid : null;
    return {
        uid,
        error: state.auth.error,
        islogged: state.auth.islogged
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminHome);