import React, { Component } from 'react'
import MobileNav from '../../Components/mobile-nav'
import Header from '../../Components/header'
import Footer from '../../Components/footer';
import { openNav } from '../../Services/utils';
import { Link } from 'react-router-dom';
import { withFirestore, firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

class Blog extends Component {
  constructor(props) {
    super(props)
    this.state = {};
    window.scrollTo(0, 0);
  }

  render() {
    const { blogs, isloading } = this.props;
    return (
      <div>
        <MobileNav />
        <span onClick={e => openNav()} className="open-mobile-navigation"><i className="fa fa-bars" /></span>
        <div id="site">
          <Header />
          {/* <Slideshow /> */}
          <div className="header-block">
            <div className="inner">
              <div className="header-block-image" style={{ marginTop: '1.5em' }}>
                <img width={650} height={700} src={`${process.env.PUBLIC_URL}/assets/images/illustrators/blog.jpg`} alt="" />
              </div>
              <div className="header-block-content dark">
                <div className="header-block-content-table">
                  <div className="header-block-content-table-cell">
                    <p className="header-title" style={{ margin: 0 }}>Blogs</p>
                    <p>I will Enchance my self - worth stature and contribution towards all Important stake holders of my life by refining myself personally, professionally and spiritually in order to create a mutually win - win endeavour for all.</p>

                    {/* <a href="../services.html" className="more-link">Read More</a> */}
                  </div>
                </div>
              </div>
            </div>
            <svg className="graphic blog-header-graphic-2" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" style={{ enableBackground: 'new 0 0 1000 1000' }} xmlSpace="preserve">
              <g id="eYHrwQ.tif">
                <g>
                  <path d="M225.66,830.19c1.22-71.41,22.97-118.34,66.52-154.56c22.51-18.72,46.03-36.22,69.23-54.1
                    c43.99-33.9,67.75-79.41,76.81-133.37c6.97-41.5,5.91-83.12,2.1-124.85c-4.32-47.3-10.8-94.51-9.03-142.17
                    c1.63-43.64,8.64-86.06,32.74-123.84c16.96-26.59,38.21-48.92,66.64-63.19c46.75-23.46,90.69-17.34,131.34,14.67
                    c33.49,26.37,53.79,61.93,66.53,101.63c24.9,77.6,45.31,156.43,56.88,237.2c4.21,29.37,6.64,59.26,6.67,88.91
                    c0.04,45.28-6.93,89.92-19.91,133.46c-24.79,83.17-67.33,155.93-129.6,216.43C605.4,862.55,562.92,892,517.7,917.41
                    c-29,16.3-59.14,29.99-91.08,39.38c-29.73,8.74-60.03,13.55-91.04,8.45c-40.28-6.62-69.65-28.66-88.27-64.75
                    C233.59,873.89,224.92,845.86,225.66,830.19z" />
                </g>
              </g>
            </svg>
            <svg className="graphic blog-header-graphic-1" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" style={{ enableBackground: 'new 0 0 1000 1000' }} xmlSpace="preserve">
              <g id="G9GQXm.tif">
                <g>
                  <path d="M266.65,53.43c6.13,0,12.26,0,18.39,0c4.49,0.92,9,1.75,13.46,2.77c24.67,5.66,46.17,17.79,65.02,34.12
                    c23.15,20.05,45.23,41.32,68.01,61.81c24.14,21.72,51.01,38.55,83.5,44.78c23.13,4.43,46.45,4.15,69.84,3.21
                    c30.77-1.23,61.61-3.47,92.34-2.63c46.16,1.25,83.17,21.21,109.35,60c16.81,24.9,28.04,52.36,36.28,80.97
                    c27.33,94.78,54.28,189.66,81.11,284.58c7.58,26.82,12.19,54.12,11.08,82.23c-1.65,41.58-24.68,72.41-64.23,85.12
                    c-23.24,7.47-47.48,7.9-71.46,10.28c-18.64,1.85-37.43,3.56-55.7,7.45c-28.9,6.15-54.38,20.55-78.23,37.55
                    c-23.55,16.78-46.41,34.52-69.91,51.38c-31.26,22.43-64.64,40.42-103.28,46.57c-29.97,4.77-57.94-0.25-83.46-17.19
                    c-17.08-11.35-31.15-25.95-44.55-41.26c-36.06-41.23-72.34-82.28-107.99-123.87c-29.53-34.45-59.35-68.73-87.1-104.58
                    c-34.04-43.98-54.51-94.29-62.05-149.59c-1.7-12.49-2.92-25.05-4.37-37.58c0-14.18,0-28.35,0-42.53
                    c0.98-9.69,1.8-19.39,2.98-29.05c6.68-54.76,20.07-107.99,36.95-160.39c12.43-38.56,27.12-76.25,49.31-110.33
                    c9.75-14.98,21.29-29.03,33.52-42.1C221.9,67.58,243.01,57.71,266.65,53.43z" />
                </g>
              </g>
            </svg>
          </div>
          <main id="main" className="blog">
            <div className="inner test-center">
              {
                isloading ? 'LOADING...' : ''
              }
              {
                !!blogs && <> {blogs.map(blog => (
                  <article id="post-201" className="post">
                    <div className="post-thumbnail">
                      <Link to={`blog/${blog.id}`}><img className="img-fluid" src={blog.coverImg} alt={blog.id} /></Link>
                    </div>
                    <div className="post-content">
                      <div className="post-content-table">
                        <div className="post-content-table-cell">
                          <h2 className="post-title"><Link to={`blog/${blog.id}`}>{blog.title}</Link></h2>
                          <div className="post-meta">
                            <div className="post-date">{moment(blog.date.toDate()).format('DD MMM YYYY hh:ss A')}</div>
                          </div>
                          <div className="entry-content">
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean accumsan consequat fringilla. Etiam luctus nec arcu eu tempor. Ut quis blandit dolor. Aliquam non ex tellus. Mauris placerat luctus nunc et vehicula. Cras vel metus ut ipsum tempus scelerisque sed nec ligula. Quisque a ultricies massa, in fermentum leo. Vestibulum orci mi, placerat quis rhoncus […]</p> */}
                            <Link to={`blog/${blog.id}`} className="more-link">Read More</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                ))}
                  <Link to={`blogs`} className="more-link">Show more</Link></>
              }
              {/* <div className="pagination">
                <span aria-current="page" className="page-numbers current">1</span>
                <a className="page-numbers" href="page/2/index.html">2</a>
                <a className="next page-numbers" href="page/2/index.html">Next »</a>
              </div> */}
            </div>
          </main>
          <Footer />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const uid = state.firebase.auth.uid ? state.firebase.auth.uid : null;
  return {
    uid,
    blogs: state.firestore.ordered.blogs,
    isloading: state.firestore.status.requesting.blogs
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
  };
};
export default compose(
  // firestoreConnect(() => ['blogs']), // or { collection: 'todos' }
  firestoreConnect((ownProps) => [
    {
      collection: "blogs",
      orderBy: [['date', 'desc']]
    },
  ]),
  connect(mapStateToProps, mapDispatchToProps)
)(withFirestore(Blog))
