import React, { Component } from 'react'
import MobileNav from '../../Components/mobile-nav'
import Header from '../../Components/header'
import Footer from '../../Components/footer';
import { openNav } from '../../Services/utils';
import { withFirestore } from 'react-redux-firebase';
import moment from 'moment';

class Post extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isloading: true,
            blog: {},
            blogId: props.match.params ? props.match.params.blogid : null
        }
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        if (this.state.blogId) {
            this.props.firestore.get({ collection: 'blogs', doc: this.state.blogId }).then(doc => {
                this.setState({
                    isloading: false,
                    blog: doc.data()
                })
            })
        }
    }

    render() {
        const { blog, isloading } = this.state
        return (
            <div>
                <MobileNav />
                <span onClick={e => openNav()} className="open-mobile-navigation"><i className="fa fa-bars" /></span>
                <div id="site">
                    <Header />
                    <main id="main" className="single-post">
                        {
                            isloading ? <article id="post-201" className="post-201 post type-post status-publish format-standard has-post-thumbnail hentry category-demo">LOADING...</article> : ''
                        }
                        {
                            !blog ? <article id="post-201" className="post-201 post type-post status-publish format-standard has-post-thumbnail hentry category-demo"> No data</article> : ''
                        }
                        {
                            (!isloading && !!blog) && <article id="post-201" className="post-201 post type-post status-publish format-standard has-post-thumbnail hentry category-demo">
                                <h1 className="post-title">{blog.title}</h1>
                                <div className="post-meta">
                                    <div className="post-date">
                                        {moment(blog.date.toDate()).format('DD MMM YYYY hh:ss A')}
                                    </div>
                                    {/* <div className="post-author">
                                    <a href="../../../../author/snug/index.html" title={blog.title} rel="author">snug</a>
                                </div> */}
                                </div>
                                <div className="post-thumbnail">
                                    <img width={1000} height={800} src={blog.coverImg} alt={blog.id} className="attachment-thrive-featured-image size-thrive-featured-image wp-post-image" />
                                </div>
                                <div className="post-content">
                                    <div className="entry-content" dangerouslySetInnerHTML={{ __html: blog.content }}>

                                    </div>
                                </div>
                                {/* <div className="socialShare">
                                    <h3>Share this post?</h3>
                                    <a href="https://twitter.com/intent/tweet?text=Another%20Sample%20Post%20-%20https://thrive.snugdesigns.co.uk/2019/08/17/another-sample-post/"><i className="fab fa-twitter" /></a>
                                    <a href="http://www.facebook.com/sharer.php?u=https://thrive.snugdesigns.co.uk/2019/08/17/another-sample-post/&t=Another%20Sample%20Post" title="Share on Facebook." target="_blank"><i className="fab fa-facebook" /></a>
                                    <a href="../../../../../pinterest.com/pin/create/button/index7fd7.html?url=https://thrive.snugdesigns.co.uk/2019/08/17/another-sample-post/&media=https://thrive.snugdesigns.co.uk/wp-content/uploads/2019/08/stil-1220499-unsplash.jpg" data-pin-custom="true"><i className="fab fa-pinterest" /></a>
                                    <a href="mailto:?subject=Another Sample Post&body=Another Sample Post - https://thrive.snugdesigns.co.uk/2019/08/17/another-sample-post/"><i className="far fa-envelope" /></a>
                                </div> */}
                                {/* <div className="post-navigation">
                                    <div className="prev-post-link">
                                        <a href="../../../06/13/a-sample-test-post/index.html" rel="prev">Previous Post</a>
                                    </div>
                                    <div className="next-post-link">
                                    </div>
                                </div> */}
                            </article>}
                    </main>
                    <Footer />
                </div>
            </div>
        )
    }
}

export default withFirestore(Post);