import React, { Component } from 'react'
import './style.css';
import AdminHeader from '../../../Components/admin-header'
import { connect } from 'react-redux';
import Editor from '../../../Components/editor';
import { compose } from 'redux';
import { withFirestore, withFirebase } from 'react-redux-firebase';

class AdminCreateBlog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            editorHtml: '',
            theme: 'snow',
            blogId: props.match.params ? props.match.params.blogid : '',
            isloading: true
        }
        if (!localStorage.getItem('isLoggedIn')) {
            this.props.history.push('/login');
        }
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        if (this.state.blogId)
            this.props.firestore.get({ collection: 'blogs', doc: this.state.blogId }).then(doc => {
                this.setState({
                    isloading: false,
                    ...doc.data(),
                    editorHtml: doc.data().content || ''
                })
            })
    }

    componentWillUnmount() {

    }

    onEditorChange = (html) => {
        this.setState({ editorHtml: html });
    }

    onChange = e => {
        if (e.target.name === 'cover') {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;


            this.setState({
                cover: files[0],
            })

            return 0;
        }
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = e => {
        e.preventDefault()
        const blog = {
            title: this.state.title,
            content: this.state.editorHtml
        }
        this.setState({
            isloading: true
        }, () => {
            if (this.state.cover) {
                this.props.firebase.uploadFile('blogs', this.state.cover)
                    .then(snapshot => {
                        snapshot.uploadTaskSnaphot.ref.getDownloadURL().then(url => {
                            if (this.state.blogId) {
                                debugger
                                this.props.firestore.update(
                                    { collection: 'blogs', doc: this.state.blogId },
                                    {
                                        ...blog,
                                        authorId: this.props.uid,
                                        coverImg: url,
                                        date: new Date(),
                                    }).then(snap => {
                                        this.setState({
                                            isloading: false
                                        })
                                    })
                            } else {
                                this.props.firestore
                                    .collection("blogs")
                                    .add({
                                        ...blog,
                                        authorId: this.props.uid,
                                        coverImg: url,
                                        date: new Date(),
                                    })
                                    .then((resp) => {
                                        this.props.history.push('/blog')
                                        // dispatch({
                                        //     type: "ADD_BLOG",
                                        //     blog,
                                        // });
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                        // dispatch({
                                        //     type: "ADD_BLOG_ERR",
                                        //     err,
                                        // });
                                    });
                                this.setState({
                                    isloading: false
                                })
                            }
                        });
                    }).catch(err => {
                        console.log(err);
                    })
            } else {
                this.props.firestore.update(
                    { collection: 'blogs', doc: this.state.blogId },
                    {
                        ...blog,
                        authorId: this.props.uid,
                        coverImg: this.state.coverImg,
                        date: new Date(),
                    }).then(snap => {
                        this.props.history.push('/blog')
                    })
                this.setState({
                    isloading: false
                })
            }
        })
    }

    render() {
        const { editorHtml, blogId, coverImg, id } = this.state;
        return (
            <div>
                <div>
                    <AdminHeader />
                    <div className="page-container" style={styleSheet}>
                        <h2> {blogId ? 'Edit' : 'Create New'} Blog</h2>
                        <div class="row">
                            <div class="col-md-12 contact-wrapper">
                                <div className="contact-form" style={{ width: '100%' }}>
                                    <div className="screen-reader-response" aria-live="polite" />
                                    <form onSubmit={this.onSubmit} className="" noValidate="novalidate">
                                        <p>
                                            <span className=" your-name">
                                                <input type="text" onChange={this.onChange} value={this.state.title} placeholder="Title" name="title" size={40} className="" aria-required="true" aria-invalid="false" />
                                            </span> </p>
                                        <p>
                                            <label>Cover Image<br /></label>
                                            <span className="text-center">
                                                <input onChange={this.onChange} type="file" name="cover" size={40} className="" aria-required="true" aria-invalid="false" />
                                                {
                                                    !!coverImg && <img width={200} src={coverImg} alt={id} />
                                                }
                                            </span> </p>
                                        <p>
                                            <span className="your-message editor">
                                                <Editor placeholder={'Write something...'} content={editorHtml} onChange={this.onEditorChange} />
                                                {/* <textarea name="your-message" cols={40} rows={10} className="wpcf7-form-control wpcf7-textarea" aria-invalid="false" /> */}
                                            </span> </p>
                                        <p><input type="submit" value="Post" className="" /></p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const styleSheet = {
    margin: '3vw 10vw'
}

const mapStateToProps = (state) => {
    const uid = state.firebase.auth.uid || null;
    return {
        uid,
        error: state.auth.error,
        islogged: state.auth.islogged
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default compose(
    // firestoreConnect(() => ['blogs']), // or { collection: 'todos' }
    // firestoreConnect((ownProps) => [
    //     {
    //         collection: "blogs",

    //     },
    // ]),
    connect(mapStateToProps, mapDispatchToProps)
)(withFirestore(withFirebase(AdminCreateBlog)))
// export default connect(mapStateToProps, mapDispatchToProps)(AdminBlog);