import React, { Component } from 'react';
import './login.css';
import { connect } from 'react-redux';
import { signIn, signOut } from '../../../Store/actions/auth';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: ''
        };
        window.scrollTo(0, 0);
        // localStorage.clear();

    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (prevProps.uid !== this.props.uid) {
            this.props.history.push('/dashboard');
        }
        if (prevProps.error !== this.props.error) {
            this.setState({
                error: this.props.error
            })
        }
    }

    onChange = (key, value) => {
        this.setState({
            [key]: value,
            error: ''
        })
    }

    onLogin = (e) => {
        e.preventDefault();
        if (!this.state.username) {
            this.setState({
                error: 'username is blank'
            })
            return false;
        }
        if (!this.state.password) {
            this.setState({
                error: 'password is blank'
            })
            return false;
        }

        this.props.signIn({ email: this.state.username, password: this.state.password })
    }

    render() {
        const { error } = this.state;
        return (
            <div className="login">
                {/* <img src="assets/images/rush-logo.png" alt="rush investment" style={{width:'25%'}}/> */}
                <div className="container" id="container">
                    <div className="form-container sign-in-container">
                        <form>
                            <h1>Login</h1>
                            <div className="social-container">
                                {
                                    error ? <span className="error-message"> {error}</span> : <></>
                                }
                            </div>
                            <input type="email" onChange={e => this.onChange('username', e.target.value)} placeholder="Email" />
                            <input type="password" onChange={e => this.onChange('password', e.target.value)} placeholder="Password" />
                            <br></br>
                            <button onClick={e => this.onLogin(e)}>Login  </button>
                        </form>
                    </div>
                    <div className="overlay-container">
                        <div className="overlay">
                            <div className="overlay-panel overlay-right">
                                <h1>Welcome Back!</h1>
                                <p>To keep connected with us please login with your personal info</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const uid = state.firebase.auth.uid;
    return {
        uid: uid,
        error: state.auth.error,
        loading: state.firebase.isInitializing
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds)),
        signOut: () => dispatch(signOut()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);