import React, { Component } from 'react';
import Header from '../../Components/header/index';
import MobileNav from '../../Components/mobile-nav';
import { Link } from "react-router-dom";
import Footer from '../../Components/footer';
import { openNav } from '../../Services/utils';
import Testimonial from '../../Components/testimony';
import { withFirestore, firestoreConnect, isLoaded } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';

class Homepage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
  }
  render() {
    const { blogs } = this.props;
    return (
      <div>
        <MobileNav />
        <span onClick={e => openNav()} className="open-mobile-navigation"><i className="fa fa-bars" /></span>
        <div id="site">
          <Header />
          <div className="header-block">
            <div className="inner">
              <div className="header-block-image" style={{ borderRadius: '50%' }}>
                {/* <img src={`${process.env.PUBLIC_URL}/assets/images/illustrators/insurance.png`} alt="" /> */}
                <img width={1000} height={800} src={`${process.env.PUBLIC_URL}/assets/images/rupa_shah.jpeg`} alt="" />
              </div>
              <div className="header-block-content dark">
                <div className="header-block-content-table">
                  <div className="header-block-content-table-cell">
                    <h3 className="header-title" style={{ fontSize: 'xx-large' }}>Your Safety is My Repsonsibility</h3>
                    <div>
                      <p>As we give great insurance coverage at affordable insurance rates. Our top priority is to value about your well-being and create a secured environment for you.</p>
                    </div>
                    <Link to="contact" className="more-link">Contact Us</Link>
                  </div>
                </div>
              </div>
            </div>
            <svg className="graphic home-header-graphic-1" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" style={{ enableBackground: 'new 0 0 1000 1000' }} xmlSpace="preserve">
              <g id="G9GQXm.tif">
                <g>
                  <path d="M266.65,53.43c6.13,0,12.26,0,18.39,0c4.49,0.92,9,1.75,13.46,2.77c24.67,5.66,46.17,17.79,65.02,34.12
                     c23.15,20.05,45.23,41.32,68.01,61.81c24.14,21.72,51.01,38.55,83.5,44.78c23.13,4.43,46.45,4.15,69.84,3.21
                     c30.77-1.23,61.61-3.47,92.34-2.63c46.16,1.25,83.17,21.21,109.35,60c16.81,24.9,28.04,52.36,36.28,80.97
                     c27.33,94.78,54.28,189.66,81.11,284.58c7.58,26.82,12.19,54.12,11.08,82.23c-1.65,41.58-24.68,72.41-64.23,85.12
                     c-23.24,7.47-47.48,7.9-71.46,10.28c-18.64,1.85-37.43,3.56-55.7,7.45c-28.9,6.15-54.38,20.55-78.23,37.55
                     c-23.55,16.78-46.41,34.52-69.91,51.38c-31.26,22.43-64.64,40.42-103.28,46.57c-29.97,4.77-57.94-0.25-83.46-17.19
                     c-17.08-11.35-31.15-25.95-44.55-41.26c-36.06-41.23-72.34-82.28-107.99-123.87c-29.53-34.45-59.35-68.73-87.1-104.58
                     c-34.04-43.98-54.51-94.29-62.05-149.59c-1.7-12.49-2.92-25.05-4.37-37.58c0-14.18,0-28.35,0-42.53
                     c0.98-9.69,1.8-19.39,2.98-29.05c6.68-54.76,20.07-107.99,36.95-160.39c12.43-38.56,27.12-76.25,49.31-110.33
                     c9.75-14.98,21.29-29.03,33.52-42.1C221.9,67.58,243.01,57.71,266.65,53.43z" />
                </g>
              </g>
            </svg>
            <svg className="graphic home-header-graphic-2" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" style={{ enableBackground: 'new 0 0 1000 1000' }} xmlSpace="preserve">
              <g id="G9GQXm.tif">
                <g>
                  <path d="M406.55,41c3.38,0,6.75,0,10.12,0c2.82,0.86,5.61,2.3,8.48,2.5c22.51,1.56,45.23,1.5,67.53,4.52
                     c97.99,13.25,190.09,43.31,271.31,101.23c53.37,38.05,93.94,86.7,116.35,149.34c16.22,45.36,23.45,92.41,23.06,140.11
                     c-0.37,45.09-3.55,90.28-7.96,135.18c-5.91,60.17-16.19,119.69-37.22,176.8c-20.41,55.42-49.95,104.73-96.64,142.19
                     c-63.07,50.6-132.6,65.73-209.38,35.9c-39.6-15.38-73.07-40.32-103.26-69.88c-43.17-42.27-77.01-92.04-112.45-140.48
                     c-43.33-59.24-89.83-115.3-145.9-163.41c-39.69-34.06-75.72-72.18-104.71-116.25c-21.72-33.02-38.46-68.12-44.3-107.68
                     c-1.22-8.27-2.39-16.54-3.59-24.81c0-1.35,0-2.7,0-4.05c0.78-3.58,2.07-7.13,2.27-10.74c2.09-38.47,17.15-72,40.07-102.07
                     C148.51,99.97,242.47,58.66,351.31,45.85C369.66,43.69,388.13,42.59,406.55,41z" />
                </g>
              </g>
            </svg>
          </div>
          <div className="featured-pages colour5">
            <div className="inner">
              <div className="featured-pages-single">
                <Link to="/blogs">
                  <img width={500} height={500} src={`${process.env.PUBLIC_URL}/assets/images/illustrators/blog.svg`} className="" alt="" sizes="(max-width: 500px) 100vw, 500px" />
                  <p>Blog</p>
                </Link>
              </div>
              <div className="featured-pages-single">
                <Link to="/services">
                  <img width={500} height={500} src={`${process.env.PUBLIC_URL}/assets/images/illustrators/services.svg`} className="" alt="" sizes="(max-width: 500px) 100vw, 500px" />
                  <p>Services</p>
                </Link>
              </div>
              <div className="featured-pages-single">
                <Link to="/about">
                  <img width={500} height={500} src={`${process.env.PUBLIC_URL}/assets/images/illustrators/about.svg`} className="" alt="" sizes="(max-width: 500px) 100vw, 500px" />
                  <p>About</p>
                </Link>
              </div>
            </div>
          </div>
          <div className="about-block about-block-home">
            <div className="inner">
              <div className="about-block-images">
                <div className="image-top" style={{ width: '80%' }}><img width={500} height={500} src={`${process.env.PUBLIC_URL}/assets/images/illustrators/whoweare.png`} className="" alt="" /></div>
              </div>
              <div className="about-block-content">
                <div className="about-block-content-table">
                  <div className="about-block-content-table-cell">
                    <h2>Who Are We?</h2><br />
                    <p>As an insurance advisor, contribute towards financial security and wealth
creation of many families.I have a successful track record of helping my consumers prioritize their financial goals creating wealth and planning their legacyMy sales mission is to help families stay financially protected.</p>
                    <Link to="about" className="more-link">Know more</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-block colour2 text-center" style={{ padding: '4%' }}>
            <h2>What my clients have said about my work</h2>
            <Testimonial />

          </div>

          <div className="latest-posts-home colour3">
            <div className="inner">
              {
                !isLoaded
                  ? <div className="text-center"> Loading... </div>
                  : !!blogs
                    ? blogs.map(blog => (
                      <div className="latest-posts-home-single text-center">
                        <Link to={`blog/${blog.id}`}><img src={blog.coverImg} className="img-thumbnail" alt={blog.id} style={{ maxHeight: 250 }} /></Link>
                        <h3><Link to={`blog/${blog.id}`}>{blog.title}</Link></h3>
                      </div>
                    ))
                    : ''
              }
            </div>
            {!!blogs && <Link to="blogs" className="more-link">More</Link>}
          </div>

          <Footer />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const uid = state.firebase.auth.uid ? state.firebase.auth.uid : null;
  return {
    uid,
    blogs: state.firestore.ordered.blogs,
    isloading: state.firestore.status.requesting.blogs
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
  };
};
export default compose(
  // firestoreConnect(() => ['blogs']), // or { collection: 'todos' }
  firestoreConnect((ownProps) => [
    {
      collection: "blogs",
      orderBy: [['date', 'desc']],
      limit: 3
    },
  ]),
  connect(mapStateToProps, mapDispatchToProps)
)(withFirestore(Homepage));